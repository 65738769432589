<template>
  <div class="h5">
    <div class="main">
      <div class="title2 fs32 bold cole20306">智慧环卫 Wisdom Sanitation</div>
      <div class="title mTop60 maLf16">
        <div></div>
        <div>产品介绍</div>
      </div>
      <div class="main-item alCen-jcSB mTop32">
        <div>
          和财智慧环卫系统是数字环卫系统的延伸和发展。系统采用物联网、云计算、大数据、移动互联网、空间地理信息集成等新一代信息技术，把各种硬件设备应用到环卫管控对象中，通过环卫云技术将环卫领域物联网整合起来。同时借助移动互联网技术，将人类社会与环卫业务系统紧密联系起来，以更加精细和智能的方式实现环境管理和决策的智慧化。
        </div>
        <img
          src="		https://www.hcwisdom888.com:8881/hcwisdom/profile/upload/2022/09/07/52d16228-0c95-4dcc-89b1-95a3b95bfc33.jpg"
          alt=""
        />
      </div>
      <div class="title mTop60 maLf16">
        <div></div>
        <div>产品功能</div>
      </div>
      <div class="list flex jsSB mTop32">
        <div class="list-item">
          <div class="f-a-j">
            <div>感</div>
          </div>
          <div class="lh32" style="width: 60%; margin: 32px auto 0 auto">
            利用任何可以随时随地的感知、测量、捕获和传递信息的设备系统，实现对人员、车辆、垃圾桶、公厕等环卫要素的更透彻的感知。
          </div>
        </div>
        <div class="list-item">
          <div class="f-a-j">
            <div>传</div>
          </div>

          <div class="lh32 mTop32" style="width: 60%; margin: 32px auto 0 auto">
            利用环卫专网、运营商网络、结合3G/4G卫星通讯等技术，将各系统中存储的环卫信息进行交互和共享，实现更全面的互联互通。
          </div>
        </div>
        <div class="list-item">
          <div class="f-a-j">
            <div>知</div>
          </div>
          <div class="lh32 mTop32" style="width: 60%; margin: 32px auto 0 auto">
            以云计算、虚拟化和高性能计算等技术手段，整合和分析海量的人员、部件和事件信息，实现海量存储、实时处理、深度挖掘和模型分析，实现更加深入的智能化。
          </div>
        </div>
        <div class="list-item">
          <div class="f-a-j">
            <div>用</div>
          </div>

          <div class="lh32 mTop32" style="width: 60%; margin: 32px auto 0 auto">
            利用云服务的模式建立面向对象的业务应用系统和服务门户，实现更智慧的决策。
          </div>
        </div>
      </div>
      <div class="title mTop60 maLf16">
        <div></div>
        <div>人员管理</div>
      </div>
      <div class="mTop32 fs14 lh32">
        <span class="fs18 bold cole20306"
          >解决人员调度不及时，考勤管理不到位的问题。</span
        >
        实现由“人管人”、“走动式”、“行政性”管理向机制化、制度化、办法管人的转变。为管理人员配发卫星定位的环卫通手机，设定管理区域，设置管理范围。管理人员，在平台制定的区域进行作业、管理。当人员在作业时间内，走出规定区域，平台会自动报警，指挥调度人员会在第一时间发现并掌握具体实情，及时巡查工作人员的在岗情况。如无特殊原因，将对工作人员提示警告、批评；情节严重的，指挥调度人员及时报相关科室和领导，杜绝工作人员缺岗失位、工作散漫等现象。
      </div>
      <div class="rlgl mTop32 colFFF flex wrap jsSB">
        <div v-for="(item, index) in list" :key="index" class="rlgl-item">
          <div class="fs18 bold">
            {{ index + 1 + "." + item.title }}
          </div>
          <div class="fs14">
            {{ item.content }}
          </div>
        </div>
        <div class="rlgl-item"></div>
      </div>
      <div class="title mTop60 maLf16">
        <div></div>
        <div>事件管理</div>
      </div>
      <div class="mTop32 fs14 lh32">
        <span class="fs18 bold cole20306">解决事件处置效率不高的问题。</span>
        综合利用各种技术，实现多样化指挥调度，将环卫作业车辆、人员形成一个信息互通、有机协同的整体。针对城市环卫事件建立专业的事件处置机制，同时整合业务部门资源，明确各部门角色分工。
      </div>
      <div class="main-view2">
        <div class="alCen jsSB">
          <div style="width: 55%" class="fs14">
            <div>
              <span class="cole20306"> 建立三大机制：</span>
              第一时间发现问题；第一时间处置问题；第一时间解决问题。
            </div>
            <div class="mTop32">
              <span class="cole20306"> 实现五大转变：</span>
              粗放向精细；被动向主动；静态向动态；单一向互动；传统向现代转变。通过智慧环卫采用“防微杜渐”更为科学的管理模式，配合现有的管理机制，可以大大减少甚至可以有效遏制很多问题的发生。
            </div>
            <div class="mTop32">
              <span class="cole20306"> 事件上报：</span>
              发现事件问题进行上报，并可以添加图片、音频、视频，可以转为考核。
            </div>
          </div>
          <img
            src="https://www.hcwisdom888.com:8881/hcwisdom/profile/upload/2022/09/07/167d66bb-1411-4430-a2f0-77a9dea6cf90.jpg"
            class="img4"
          />
        </div>
        <div class="alCen jsSB mTop60">
          <img
            src="	https://www.hcwisdom888.com:8881/hcwisdom/profile/upload/2022/09/07/3afc40a8-18ff-46d1-ad1d-cd2ca5669393.jpg"
            class="img4"
          />
          <div style="width: 55%" class="fs14">
            <div>
              <span class="cole20306"> 事件上报：</span>
              发现事件问题进行上报，并可以添加图片、音频、视频，可以转为考核。
            </div>
            <div class="mTop32">
              <span class="cole20306"> 待分配：</span>
              已经上报但是还没有分配的事件。
            </div>
            <div class="mTop32">
              <span class="cole20306"> 待处理：</span>
              已经分配给登陆人但是还没有处理的事情。
            </div>
            <div class="mTop32">
              <span class="cole20306"> 已完成：</span>
              已经处理完结的事件，这个事件已经处理结束了。
            </div>
            <div class="mTop32">
              <span class="cole20306"> 统计查询：</span>
              已上报的多少，待分配的多少，待处理的多少，已完成的多少，日报，周报，月报。
            </div>

            <div class="mTop32">
              <span class="cole20306"> 事件位置：</span>
              在地图上展示事件的位置，点击可以查看详情，PC端也能够进行分类查看和处置。
            </div>
          </div>
        </div>
      </div>
      <div class="title mTop60 maLf16">
        <div></div>
        <div>部件管理</div>
      </div>
      <div class="mTop32 fs14 lh32">
        <span class="fs18 bold cole20306">解决部件管理无序化的问题。</span>
        系统实现了地图的浏览、显示等常规的GIS功能,并实现了环卫部件基本信息的浏览、查询以及可视化显示,并对城市部件的突发事件做到及时响应,并实现了已知的位置前往事发地点的最短路径分析,减少了事件处理前不必要的时间浪费。目标系统已经在实际生活中应用,做到了简单、高效的部件管理和实时的事件响应。环卫设施管理包括对垃圾桶、公厕、中转站等重要环卫设施进行信息化管理，基本管理内容包括基于地图标注、更新、删除、查询环卫设施分布情况，及时登记环卫设施维修维护情况。并可以和RFID卡进行对接，对每个环卫设施建立身份证。
      </div>
      <div class="fs14">
        <div class="mTop60">
          <span class="cole20306"> 部件问题：</span>
          部件问题的上报，上报时候选择部件的大类、小类，最好能够上报的时候自动定位到某个部件，并可以进行位置的调整。
        </div>
        <div class="mTop32">
          <span class="cole20306"> 部件更新：</span>

          部件的添加、编辑、删除。部件添加时能够自动定位位置，上传到平台，然后在平台和手机展示。
        </div>
        <div class="mTop32">
          <span class="cole20306"> 部件位置：</span>

          在地图上显示部件的位置，点击可以查看部件的详情。
        </div>
        <div class="mTop32">
          <span class="cole20306"> 统计查询：</span>

          每个大类部件有多少，以柱状图显示出来，然后点击大类可以查看详情。
        </div>
      </div>
      <div class="title mTop60 maLf16">
        <div></div>
        <div>GIS管理</div>
      </div>
      <div class="main-view2 mTop60">
        <div class="alCen jsSB">
          <div style="width: 55%" class="fs16">
            <div class="lh32">
              <span class="cole20306"> 主要解决GIS管理的问题。</span>
              利用统一的GIS平台，将管辖范围内的所有环卫数据、空间数据进行整合，形成“环卫管理一张图”，提供全局视野俯瞰管理区域的全景视图，直观展示环卫管理工作全貌、分析环卫现状、及时协同调度，为科学决策提供有力的支撑。
            </div>
          </div>
          <img
            src="https://www.hcwisdom888.com:8881/hcwisdom/profile/upload/2022/09/07/167d66bb-1411-4430-a2f0-77a9dea6cf90.jpg"
            class="img4"
          />
        </div>
      </div>
      <div class="title mTop60 maLf16">
        <div></div>
        <div>移动端应用</div>
      </div>
      <div class="alCen-jcSB mTop32">
        <img
          src="https://www.hcwisdom888.com:8881/hcwisdom/profile/upload/2022/09/07/66bdb0d9-c5fb-4a03-bca2-fb2bea560e9d.jpg"
          alt=""
          class="img5"
        />
        <div style="width: 50%" class="lh32 fs16">
          <span class="cole20306">主要解决移动端应用问题。</span>
          移动端应用是为适应各类不同类型终端（平板电脑、智能手机）设备而建立的移动执法业务应用软件，以Android平台为支撑，建设移动终端基础平台组件，组件包括：移动接入、移动安全、消息推送、智能更新、GPS和GIS等功能，基础平台组件将支持主流移动终端系统版本，支持不同分辨率的移动终端设备，包括手机设备和平板电脑设备。移动终端应用功能主要包括人员管理、事件管理、现场考核、部件上报、实时对讲、数据查询、任务处置、通知公告等。打破时间空间的限制，随时随地开展业务处理、了解环卫管理动态，让环卫业务处理更加灵活、便捷。
        </div>
      </div>
      <div class="title mTop80 maLf16">
        <div></div>
        <div>车辆管理</div>
      </div>
      <div class="main-view2 mTop40">
        <div class="alCen jsSB">
          <div style="width: 55%" class="fs16">
            <div class="lh32">
              <span class="cole20306"> 主要解决车辆管理不到位的问题。</span>
              子系统是基于无线网络对各类环卫车辆进行集中管理、监控及管控的系统。系统以高品质、高效率的集中化、分布式网络管理为架构，以视频、音频、数据等多媒体信息的网络传输为基础，为环卫车辆执行实时监视、GPS定位、录像存储、车辆调度及报警预警的安全防范工作。系统可实现无线远程实时视频监控、GPS地图定位、车辆历史行驶轨迹回放、语音对讲、报警联动、远程录像存储、远程下载录像、远程录像回放、车辆区域管理、车辆远程升级、车辆短信下发、丰富车辆报表（速度，油量等）、查看报警信息等等。它采用了高速处理器和嵌入式操作系统，结合了IT领域各项最新技术，无线传输、音视频压缩、解压缩技术等，结构上采用了特殊的防震技术，软件上采用特定的硬盘保护技术，有效地解决车载DVR经常碰到的硬盘出错问题，并具有良好的抗震性、散热性和稳定性，专门为各种环卫车辆量身打造的。
            </div>
          </div>
          <img
            src="https://www.hcwisdom888.com:8881/hcwisdom/profile/upload/2022/09/07/bc10e542-d9b5-4a20-93f7-27b8acdfaf2e.jpg"
            class="img4"
          />
        </div>
      </div>
      <div class="title mTop60 maLf16">
        <div></div>
        <div>评价考核</div>
      </div>
      <div class="alCen-jcSB mTop32">
        <img
          src="	https://www.hcwisdom888.com:8881/hcwisdom/profile/upload/2022/09/07/034056a3-c631-4e01-8948-8e4d007bf7cc.jpg"
          alt=""
          class="img5"
        />
        <div style="width: 50%" class="lh32 fs16">
          <span class="cole20306">主要解决评价考核不公的问题。</span>
          采用人工打分和系统自动打分相结合的方式，由巡查人员通过手机端记录巡查发现的实际问题并对问题中所涉及的人员进行考核，系统平台进行自动计算统计，有效的避免了人工统计的失误率。巡查员根据考核标准进行记录，针对考核中存在疑问的地方做到有理可依、有据可循，提高评价考核的公平性，实现评价考核管理的智慧化。
        </div>
      </div>
      <div class="title mTop80 maLf16">
        <div></div>
        <div>垃圾收运管理</div>
      </div>
      <div class="main-view2 mTop40">
        <div class="alCen jsSB">
          <div style="width: 40%" class="fs16">
            <div class="lh32">
              <span class="cole20306">
                立足于满足省、市、区（县）等各级环卫主管单位及环卫作业单位的业务管理及监督管理的需要，</span
              >
              实现对生活垃圾的收集、清运、中转、处置、产废物的全流程、全链条的透明、可视化的业务管理及监管考核，全面提升城市生活垃圾收运能力，助力智慧环卫建设。垃圾收运管理主要包括对垃圾收运的管理、垃圾中转站的监管、填埋场的监管、焚烧场的监管、转运车辆的监管等。
            </div>
          </div>
          <img
            src="	https://www.hcwisdom888.com:8881/hcwisdom/profile/upload/2022/09/07/ba896069-ef95-4c78-adf3-a4fa11e8ce27.jpg"
            class="img6"
          />
        </div>
      </div>
      <div class="title mTop80 maLf16">
        <div></div>
        <div>大数据管理中心</div>
      </div>

      <div class="lh32 mTop32 fs16">
        <span class="cole20306">
          通过在环境卫生领域多年的沉浸与客户的言传身教，和财构建起智慧环卫大数据管理中心。</span
        >
        和财在数据建模和数据管理能力等方面进行了深入的探究，包括数据如何进行采集、如何进行建模，以及如何最快的计算和最高效的存储等。最终的表现形式，不只是一个报表，而是用和财的大数据产品去理解环卫业务，理解环卫的目标，不以枯燥的表格的方式呈现给用户。利用大数据分析技术告诉客户这个数据的含义是什么、如何解读它，在这个过程中去追求数据价值的最大化。大数据中心管理系统是对所有的车辆、设施数据信息进行分类。车辆、设施分类管理功能是进行数据管理执行数据添加操作时需要选择的数据类型。大数据中心管理系统分类管理模块可以添加相关数据信息、并可以对已添加的数据信息进行查看，并可以在地图中显示其所在位置信息。
      </div>
      <div class="title mTop80 maLf16">
        <div></div>
        <div>公总参与与反馈</div>
      </div>

      <div class="lh32 mTop32 fs16 main-view2">
        <span class="cole20306"> 和财此套智慧环卫系统，</span>
        建立完善的环卫事件公众参与反馈子系统，提升服务水平，完善环卫作业监督考核系统,实现长效规范管理。
        系统开通环卫网络互动平台，通过环卫微博、微信交流,与市民进行互动，让广大市民走近环卫、体验环卫。公众不但可以通过平台了解到自己周围社区的环境卫生，还可以在平台管理区域范围内了解各大城市环卫动态，构成对比，发现差距，市民可以以第三方监督者的身份对当地环卫管理部门咨询或投诉，进行便捷的沟通互动。如此，既能美化有关环境卫生，解决人民群众反映的一些实际问题，又提高了管理部门的工作效率。
        环卫系统将环卫监管、作业标准、考核办法等规章制度智慧化，形成数据分析表格。通过程序化、自动化功能，自动生成、汇总、评价，进行环卫作业负分监管考核。公众的反馈可以帮助相关环卫管理部门落实监督考核结果，实时掌握一线管理、作业部门的绩效情况，及时反映各部门的响应灵敏度和处理问题的效果,提升了环卫管理的质量和水平,使环卫管理更加科学、合理、规范。
        通过这些平台的搭建，环卫事件公众参与反馈子系统实现信息采集、汇总、转办、处理的全过程，实现环卫部门以往被动处置向主动发现的角色转换，用心编织一张贴近民生、服务民生的环卫社会服务网，倾心打造人民群众满意和依赖的环卫服务品牌。
      </div>

      <div class="title mTop80 maLf16">
        <div></div>
        <div>垃圾分类管理</div>
      </div>
      <div class="mTop32 alCen-jcSB">
        <img
          src="https://www.hcwisdom888.com:8881/hcwisdom/profile/upload/2022/09/07/74a1faf1-a7ff-4cfc-a805-1fd078bd5ef8.jpg"
          alt=""
          class="img7"
        />
        <div class="lh32 fs16" style="width: 70%">
          <span class="cole20306">
            打造微环境服务运营模式，切实解决垃圾分类的源头之痛。</span
          >
          政府负责分类运输、分类收集、分类处理，切入居民社区、商场、写字楼、工厂、学校、企事业单位等公众身边的环境领域，运用互联网手段联接居民、回收人员、社区保洁员，实现面向社区的环境服务以及固废源头分类收集、分类处置的模式。同时运用互联网手段作为入口，开展环卫产业链上的逆向物流、广告、快递等业务开发，开展家庭环境服务及管家式社区增值服务等新业务模式。
        </div>
      </div>

      <div class="title mTop80 maLf16">
        <div></div>
        <div>产品管理</div>
      </div>
      <div class="lh32 mTop32 fs16 main-view2">
        <span class="cole20306"> 运行现代化的信息技术，</span>
        结合物联网、大数据、云平台等新一代的信息技术，将环卫领域下所应用到的产品进行管理。通过运用智慧化手段对产品的名称、数量、出库、入库等情况信息进行记录，实现产品的信息化管理，提高环卫领域下所应用产品管理的高效智能化。
      </div>

      <div class="title mTop80 maLf16">
        <div></div>
        <div>环卫投资建设运营企业管理</div>
      </div>
      <div class="lh32 mTop32 fs16">
        为环卫清扫保洁托管运营企业提供人、车、物、事的监管、为垃圾处理处置企业提供厂内监控、运行参数及污染物排放的监管。将环卫投资建设运营企业进行整合，实现环卫投资建设企业现代化、信息化的管理。降低环卫投资建设运营企业的管理成本，提高环卫投资建设运营企业管理的高效性。
      </div>

      <div class="title mTop80 maLf16">
        <div></div>
        <div>环卫硬件设备</div>
      </div>

      <!-- 应用平台 -->
      <el-carousel
        :interval="5000"
        indicator-position="none"
        arrow="never"
        :autoplay="false"
        height="500"
        class="app"
        @change="app_change"
        ref="carousel"
      >
        <el-carousel-item
          class="app-i"
          v-for="(item, index) in swiper"
          :key="index"
        >
          <img :src="item.img" alt="" />
        </el-carousel-item>
      </el-carousel>

      <div class="f-a-j mTop32">
        <img
          src="https://www.hcwisdom888.com:8881/hcwisdom/profile/upload/2022/09/08/5815f1bd-c309-452e-aae8-7d58361291cf.png"
          class="btn-arrow hand"
          @click="sub()"
        />
        <img
          src="https://www.hcwisdom888.com:8881/hcwisdom/profile/upload/2022/09/08/c4ca5204-5219-4ef0-8f45-11cd86a56307.png"
          class="btn-arrow maLf32 hand"
          @click="add()"
        />
      </div>

      <div class="mTop60">1</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "h5",
  components: {},
  data() {
    return {
      list: [
        {
          title: "人员档案",
          content: "人员基本档案的管理。",
        },
        {
          title: "人员考勤",
          content:
            "人员的考勤情况，分为日考勤、周考勤、月考勤情况，这里是考勤情况的查看。",
        },
        {
          title: "人员定位",
          content:
            "在手机上展示自己有权限看到的人的位置，并可以进行分组查找，把部门树形图列出来，查看自己下级的位置。",
        },
        {
          title: "人员统计",
          content: "展示有权限查看的人员的情况。",
        },
        {
          title: "人员通讯录",
          content: "通讯录和对讲功能一样。",
        },
        {
          title: "人员考核",
          content: "对人员进行加分、减分的考核，考核要加拍照和音频视频功能。",
        },
        {
          title: "签到签退",
          content: "能够定位，位置可以进行微调。",
        },
        {
          title: "水印显示",
          content: "登陆人名字、当前时间、能够拍照、拍照有水印。",
        },
      ],
      swiper: [
        {
          img: "https://www.hcwisdom888.com:8881/hcwisdom/profile/upload/2022/09/07/99694f25-d25d-48a5-bb8a-3eefb6eadf35.jpg	",
        },
        {
          img: "https://www.hcwisdom888.com:8881/hcwisdom/profile/upload/2022/09/07/dbd25045-04a9-4e88-96e8-593ae24dd2a1.jpg	",
        },
        {
          img: "https://www.hcwisdom888.com:8881/hcwisdom/profile/upload/2022/09/07/bbe50aae-4961-4bbf-9dc5-42a124d9bec8.jpg	",
        },
      ],
      l: require("@/assets/img/index/left-jiantou_png.png"),
      l_c: require("@/assets/img/index/left-jiantouxuanzhong_png.png"),
      r: require("@/assets/img/index/right-jiantou_png.png"),
      r_c: require("@/assets/img/index/right-jiantouxuanzhong_png.png"),
      num: 0,
    };
  },
  watch: {},
  methods: {
    app_change(e) {
      this.num = e;
    },
    add() {
      if (this.num != 2) this.num++;
      this.$refs.carousel.setActiveItem(this.num);
    },
    sub() {
      if (this.num != 0) this.num--;
      this.$refs.carousel.setActiveItem(this.num);
    },
  },
};
</script>

<style lang="less" scoped>
@import "./h5.css";
</style>
